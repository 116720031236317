import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Vimocity',
    useCase: 'BRANDING, UX, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
    description: `Vimocity is a startup out of Seattle that helps individuals in the construction and energy space get up and move their bodies to avoid stiffness, soreness and work related injuries. Vimocity has been a client of mine for 2 years and my team and I have developed many assets for their company. Kevin the companies CEO is a Chiropractor and very passionate about developing a brand that can work across multiple company types. From empowering the worker to educate themselves at one of Vimocity’s movemenet stations to viewing Daily Body Care routines on the Vimocity application. We are very proud of the value and products that we have delivered to help Vimocity’s brand grow and thrive.

      I am most proud of the video series we developed for their engagement with Puget Sound Energy. See the video below.`,
  },
  caseId: 'vimocity',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/C1ZobuGi-OmmZTDe4.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/C1ZobuGi-1920.jpg',
      },
    },
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
    'image14.jpg',
    'image15.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
